
const secondaryTextColor = `#a84fff`;
const black80 = `#1B1F23`;
const lightGray = `hsla(0, 0%, 0%, 0.2)`;
const highlightColor = `rgb(222, 222, 214)`;
const grey90 = `#232129`;


const initial = `initial`;

export default  {
    primary: secondaryTextColor,
    secondary: black80,
    muted: lightGray,
    highlight: highlightColor,
    heading: grey90,
    modes: {
        light: {
            background: initial,
        },
    },
    prism: {
        background: `#2f2d31`,
        comment: `#809393`,
        string: `#addb67`,
        var: `#d6deeb`,
        number: `#f78c6c`,
        constant: `#82aaff`,
        punctuation: `#c792ea`,
        className: `#ffc98b`,
        tag: `#ffa7c4`,
        boolean: `#ff5874`,
        property: `#80cbc4`,
        namespace: `#b2ccd6`,
        highlight: `hsla(0, 0%, 0%, 0.45)`,
    },
}
